@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f9f9fc;
  font-family: "Poppins", sans-serif;
}

.scrollbar::-webkit-scrollbar {
  width: 0px;
}

.scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scrollbar::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 2px;
}

.menu-container {
  text-align: center;
}

nav ul {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.nav {
  display: inline-block;
  margin: 2rem auto 0;
  color: #fff;
  text-align: left;
}

.nav a {
  display: block;
  padding: 0 16px;
  line-height: inherit;
  cursor: pointer;
}

.nav__menu {
  line-height: 45px;
  font-weight: 700;
  text-transform: uppercase;
}

.nav__menu-item {
  display: inline-block;
  position: relative;
}

.nav__menu-item:hover {
  /* background-color: #00aeef; */
}

.nav__menu-item:hover .nav__submenu {
  display: block;
}

.nav__submenu {
  font-weight: 300;
  text-transform: none;
  display: none;
  position: absolute;
  width: 180px;
  background-color: #00aeef;
}

.nav__submenu-item:hover {
  background: rgba(0, 0, 0, 0.1);
}

.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root {
  font-size: 12px !important;
  line-height: 0px !important;
  color: #2e3a48 !important;
  padding-left: 5px !important;
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root {
  border-radius: 5px !important;
  font-size: 14px !important;
  color: #2e3a48 !important;
  font-weight: 500 !important;
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root:hover {
  background-color: #1b8a79 !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  color: #ffffff !important;
  font-weight: 500 !important;
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #1b8a79 !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  color: #ffffff !important;
  font-weight: 500 !important;
}

textarea {
  resize: none;
}

textarea::-moz-placeholder, /* Firefox 18- */
textarea::-moz-placeholder, /* Firefox 19+ */
textarea::-ms-input-placeholder, /* IE 10+ */
textarea::-webkit-input-placeholder, /* Webkit based */
textarea::placeholder {
  /* Modern browsers */
  color: #87929e;
  font-size: 14px;
  font-weight: lighter;
  font-family: "Courier New", Courier, monospace;
}

.otp-input {
  width: 2.5rem !important;
  height: 3rem;
  font-size: 1.5rem;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin: 0 0.7rem;
  background: white;
  box-shadow: 0 4px 2px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

@media screen and (min-width: 800px) {
  .otp-input {
    width: 4rem !important;
    height: 4rem;
    font-size: 1.5rem;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    margin: 0 0.7rem;
    background: white;
    box-shadow: 0 4px 2px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  }
}

.required-field::after {
  content: "*";
  color: red;
  margin-left: 2px;
}

.magnify_image_cont,
.small_magnify_image {
  z-index: 100;
  height: 100% !important;
  width: 100% !important;
}

.small_magnify_image {
  object-fit: contain !important;
}
