.container {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 36px;
}

.heading {
  font-size: 16px;
  font-weight: 500;
}

@media (max-width: 768px) {
  .container {
    padding: 24px;
  }
}
