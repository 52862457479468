.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.App {
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.slick-track {
  display: flex;
}

.slick-slider {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.slick-slide {
  padding-right: 10px;
}

.slick-slide:last-child {
  padding-right: 0;
}

.slick-slide img {
  display: block;
}

.slick-list {
  margin: 0 15px;
}

.slick-prev {
  width: 50px;
  left: 0;
  top: 20%;
  position: absolute;
  height: 50px;
  background: tomato;
  z-index: 1;
  border-radius: 50%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.slick-next {
  width: 50px;
  right: 0;
  top: 20%;
  position: absolute;
  height: 50px;
  background: tomato;
  z-index: 1;
  border-radius: 50%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.slick-prev:focus,
.slick-prev:hover,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: tomato;
}

.product-actions {
  display: flex;
  justify-content: space-between;
}

/* .hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 100px;
  height: 100px;
  overflow: hidden;
} */

#PageDropDown {
  border: 0;
  outline: transparent;
}

#PageDropDown option {
  font-size: 20px !important;
  padding-left: 10px;
}

.MuiPagination-ul li {
  background: rgba(0, 0, 0, 0.03);
  margin: 5px;
}

/* .Mui-selected {
  color: white !important;
  background: #1B8A79 !important;
  border-radius: 0 !important;
} */

.main {
  box-shadow: 0px 8px 8px -4px rgba(0, 0, 0, 0.03),
    0px 20px 24px -4px rgba(0, 0, 0, 0.04), 0px 0px 4px 4px rgba(0, 0, 0, 0.04);
  background: white;
}

.price-table {
  width: 100%;
  border-collapse: collapse;
  border: 0 none;
}

.price-table tr {
  width: 33.3333%;
}

.price-table tr:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}

.price-table tr td {
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  padding: 15px 24px;
  font-size: 14px;
}

.price-table tr td:first-child {
  border-left: 0 none;
}

.price-table tr td:not(:first-child) {
  text-align: center;
}

.price-table tr.price-table-head td {
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
}

.price-table-help {
  font-size: 16px;
  font-weight: 500;
  color: "red";
}

.price-table td.price {
  padding: 16px 24px;
  font-size: 20px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.price-table td.price a {
  color: #ffffff;
  padding: 12px 32px;
  margin-top: 16px;
  font-size: 12px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  display: inline-block;
  border-radius: 64px;
}

.price-table td.price-table-popular {
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  padding: 12px 48px;
  font-weight: 700;
}

.price-table .price-blank {
  border: 0 none;
}

.socialMedia {
  padding: 0 7px;
  margin-top: 10px;
}

.socialMedia:nth-child(1) {
  padding: 0;
}

.profileTags {
  padding-left: 10px;
}

textarea {
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 10px;
  position: relative;
}

.MuiPaper-root {
  background: green;
}

.predictions-container {
  background: rgba(0, 0, 0, 0.05);
  padding: 10px;
  border-radius: 5px;
}

.predictions-container div {
  padding: 4px 4px;
}

.predictions-container div:hover {
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.error {
  color: red;
  font-size: 11px;
  margin-top: 3px;
}

.MuiDropzoneArea-textContainer {
  text-align: center;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  height: 30vh;
  background: #f9f9fc;
  width: 100%;
}

.labresult .MuiDropzoneArea-textContainer,
.labresult .MuiDropzoneArea-root {
  height: 7vh !important;
  min-height: 7vh !important;
  display: flex;
  flex-direction: row-reverse;
}

.uploadColor {
  color: #f78b8b;
}

/* .MuiTableCell-root {
  padding: 15px 0 !important;
}

.MuiTableRow-root th:nth-child(1) {
  padding-left: 15px !important;
} */

.dropzoneAreaLarge {
  height: 500px !important;
  padding: 8px;
  border-radius: 4px;
}

.dropzoneAreaLarge .MuiDropzoneArea-textContainer {
  border-radius: 4px;
  height: 100%;
}

.dropzoneAreaLarge .MuiDropzoneArea-text {
  margin: 4px 0 !important;
}

.dropzoneAreaSmall {
  height: 52px !important;
  min-height: unset !important;
  padding: 0;
}

.dropzoneAreaSmall .MuiDropzoneArea-textContainer {
  display: flex;
  flex-direction: row-reverse;
  gap: 1rem;
  align-items: center;
  height: 100%;
}

.dropzoneAreaSmall .MuiDropzoneArea-textContainer p {
  margin: 0 !important;
}

.dropzoneAreaSmall .MuiDropzoneArea-textContainer svg {
  margin-top: 6px;
  height: 20px;
  width: 20px;
}

.MuiChip-outlined {
  background-color: #fff !important;
}
